import * as React from 'react';
// import "@fortawesome/fontawesome-free/css/all.min.css";
import "./index.css";

// const HomePage = React.lazy(() => import('./pages/Homepage/Pages/Home'));
const HomePage = React.lazy(() => import('./pages/Homepage/Home'));
const SignInPage = React.lazy(() => import('./pages/auth/SignIn'));
const ForgetPasswordPage = React.lazy(() => import('./pages/auth/ForgetPassword'));
const ResetPasswordPage = React.lazy(() => import('./pages/auth/ResetPassword'));
const Features = React.lazy(() => import('./pages/Homepage/pages/Features'));
const ContactUs = React.lazy(() => import('./pages/Homepage/pages/ContactUs'));

const route = [ 
    // { path: '/app/Homepage/pages/Home', exact: true, name: 'Home Page', component: HomePage },   
    { path: '/app/Homepage/Home', exact: true, name: 'Home Page', component: HomePage},   
    { path: '/app/auth/signin', exact: true, name: 'Sign In', component: SignInPage },
    { path: '/app/auth/forgetPassword', exact: true, name: 'Forget Password', component: ForgetPasswordPage },
    { path: '/app/auth/resetPassword', exact: true, name: 'Reset Password', component: ResetPasswordPage },    
    { path: '/app/Homepage/pages/Features', exact: true, name: 'Features ', component: Features },
    { path: '/app/Homepage/pages/ContactUs', exact: true, name: 'ContactUs ', component: ContactUs },
];
export default route;
